import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import BlogEntry from '../components/blog/blog-entry'

const Post = ({ data }) => {
  return (
    <Layout>
      <div className="site-content">
        <BlogEntry post={data.markdownRemark} single={true} />
      </div>
    </Layout>
  )
}

export default Post;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image
        date(formatString: "D MMMM, YYYY")
        tags
      }
      fields {
        slug
        readingTime {
          text
        }
      }
    }
  }
`
