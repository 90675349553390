import React from 'react'
import { Link } from 'gatsby'

//import TalkyardCommentsIframe from '@debiki/gatsby-plugin-talkyard'

const BlogEntry = ({ post, single, comments = true }) => (
  <article>
    <div className="post-header">
      {single ? ( // single post no links needed
        post.frontmatter.image ? (
          <img src={post.frontmatter.image} alt={post.frontmatter.title} />
        ) : null
      ) : (
        <Link to={post.fields.slug}>
          {post.frontmatter.image ? (
            <img src={post.frontmatter.image} alt={post.frontmatter.title} />
          ) : null}
        </Link>
      )}
    </div>
    <div className="post">
      {single ? ( // single post full content
        <>
          {/* empty fragment tag needed */}
          <h1>{post.frontmatter.title}</h1>
          <div className="post-info">
            <time className="entry-date">{post.frontmatter.date}</time>
            <span>-</span>
            <time className="read-time">{post.fields.readingTime.text}</time>
          </div>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </>
      ) : (
        <>
          {/* list post show only excerpt */}
          <Link to={post.fields.slug}>
            <h2>{post.frontmatter.title}</h2>
          </Link>
          <p>{post.excerpt}</p>
        </>
      )}
    </div>
    <div className="post-footer">
      <span className="posted-on">
        <span>Posted on </span>
        <time className="entry-date">{post.frontmatter.date}</time>
      </span>
      <span className="tag-links">
        <i className="fas fa-tags" />
        {post.frontmatter.tags.map(tag => (
          <Link to={'/tags/' + tag}>{tag}</Link>
        ))}
      </span>
      {!single && post.frontmatter.single ? ( // only if not single element & the post has a single page > show read more
        <span className="float-sm-right">
          <Link
            className="btn btn-primary btn-sm btn-readmore"
            to={post.fields.slug}
          >
            Read more
          </Link>
        </span>
      ) : null}

      {comments ? ( // comments discussionId is to find the associated comments //<TalkyardCommentsIframe discussionId={post.fields.slug} />
        <div className="post-comments">

        </div>
      ) : null}
    </div>
  </article>
)

export default BlogEntry
